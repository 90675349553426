<template>
	<el-form :model="formData" ref="editAppealForm" label-width="100px">
		<el-form-item label="申诉人信息：">
			<div class="table-box">
				<div class="item">
					<div class="label">申诉人ID</div>
					<div class="text">{{formData.userId}}</div>
					<div class="label">申诉人昵称</div>
					<div class="text">{{formData.nickName}}</div>
				</div>
				<div class="item">
					<div class="label">申诉绑定手机号</div>
					<div class="text">{{formData.phoneNumber}}</div>
					<div class="label">申诉联系电话</div>
					<div class="text">{{formData.complaintPhone}}</div>
				</div>
			</div>
		</el-form-item>
		<el-form-item label="申诉关联订单信息：">
			<div class="table-box">
				<div class="item">
					<div class="label">关联订单号</div>
					<div class="text">{{formData.orderNo}}</div>
					<div class="label">订单时间</div>
					<div class="text">{{formData.orderTime}}</div>
				</div>
				<div class="item">
					<div class="label">关联售后单号</div>
					<div class="text">{{formData.afterSaleCode}}</div>
					<div class="label">售后申请时间</div>
					<div class="text">{{formData.orderTime}}</div>
				</div>
				<div class="item">
					<div class="label">售后处理人</div>
					<div class="text">{{formData.adminName}}</div>
					<div class="label">售后处理时间</div>
					<div class="text">{{formData.workerUpdateTime}}</div>
				</div>
				<div class="item">
					<div class="label">售后类型</div>
					<div class="text">{{formData.workerType == 1 ? '退款': formData.applyReason == 2 ? '退货退款': formData.applyReason == 3 ? '换货': ''}}</div>
					<div class="label">售后原因</div>
					<div class="text">{{formData.applyReason}}</div>
				</div>
			</div>
		</el-form-item>
		<el-form-item label="申诉内容：">
			<el-input v-model="formData.complaintContent"  disabled :rows="4" type="textarea" readonly></el-input>
		</el-form-item>
		<el-form-item label="图片反馈：">
			<div class="img-box" style="display: flex">
				<el-image v-for="(item, index) in formData.complaintImg" class="item" :key="index" :src="item"></el-image>
			</div>
		</el-form-item>
		<el-form-item label="申诉结果：">
			<el-select size="small" v-model="state" :disabled='!isEdit' placeholder="请选择申诉结果">
                <el-option label="待处理" :value="1" v-if="!isEdit"></el-option>
				<el-option label="撤销申诉" :value="2" v-if="!isEdit"></el-option>
				<el-option label="申诉成功" :value="4"></el-option>
				<el-option label="申诉失败" :value="3"></el-option>
			</el-select>
		</el-form-item>
		<el-form-item v-if="state === 3" label="失败原因：" prop="remark">
			<el-input v-model="remark" :rows="4" type="textarea" placeholder="请输入申诉失败原因"></el-input>
		</el-form-item>
		<div style="text-align:right;" v-if="isEdit">
			<el-button @click="cancelEdit">取消</el-button>
			<el-button type="primary" @click="saveEditOrder">确认</el-button>
		</div>
	</el-form>
</template>

<script>
    import { updateComplaintStatus } from '@/api/goods';
	export default {
		props: {
			formData: {
				type: Object,
				default: null,
			},
            isEdit:{
                type: Boolean,
				default: false,
            }
		},
		data() {
			return {
				state: 4,
				remark: '',
			}
		},
        mounted() {
            if (!this.isEdit) {
                this.state = this.formData.complaintStatus;
				this.remark = this.formData.loseCause;
            }
        },
		methods: {
			//取消申诉处理
			cancelEdit() {
				this.$refs["editAppealForm"].clearValidate();
				this.$emit('closeLayer', false);
			},
			//提交处理申诉
			async saveEditOrder() {
				let params = { 
                    adminId: localStorage.getItem("mlmzpageUserId"),
                    complaintStatus: this.state,
                    id: this.formData.id,
                    loseCause: this.remark
                };
                console.log(params);
                if (this.state == 3) {
                    if (!this.remark) {
                        this.$message({
                            type: 'error',
                            message: '请输入失败原因!'
                        });
                        return;
                    }
                }
				let { success } = await updateComplaintStatus(params);
				if(success) {
					this.$message({type: 'success', message: '操作成功！'});
					this.$emit('closeLayer', true);
				}
			},
		}
	}
</script>

<style lang="scss">
	.table-box {
		border: 1px #999999 solid;
		&>.item:nth-child(n+1) {
			border-top: 1px #999999 solid;
			display: flex;
			&>div {
				flex: 1;
			}
		}
		.label {
            padding-left: 10px;
			background: #f2f2f2;
		}
        .text{
            padding-left: 10px;
        }
	}
	.img-box {
		display: flex;
		.item {
			width: 120px;
			height: 120px;
			border-radius: 8px;
			margin-right: 12px;
		}
	}
</style>