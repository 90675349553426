<template>
	<div class="main-content">
		<el-form inline size="small" label-width="100px">
			<el-form-item label="用户ID：">
				<el-input class="input-width" v-model="filter.userId" placeholder="请输入用户ID搜索"></el-input>
			</el-form-item>
			<el-form-item label="昵称：">
				<el-input class="input-width" v-model="filter.nickName" placeholder="请输入昵称搜索"></el-input>
			</el-form-item>
			<el-form-item label="工单号：">
				<el-input class="input-width" v-model="filter.afterSaleCode" placeholder="请输入工单号搜索"></el-input>
			</el-form-item>
			<el-form-item label="申诉状态：">
				<el-select class="input-width" v-model="filter.complaintStatus" placeholder="请选择申诉状态">
					<el-option :value="''" label="全部状态"></el-option>
					<el-option :value="1" label="待处理"></el-option>
					<el-option :value="2" label="撤销申诉"></el-option>
					<el-option :value="3" label="申诉失败"></el-option>
					<el-option :value="4" label="申诉成功"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="申诉时间：">
				<el-date-picker class="input-width" v-model="createTimes" type="daterange"
								range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期"
								value-format="yyyy-MM-dd"></el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button icon="el-icon-search" type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="filter.list" v-loading="tableLoading" border>
			<el-table-column prop="afterSaleCode" label="申诉人">
				<template slot-scope="scope">
					<p style="margin: 0;">{{ scope.row.nickName }}({{ scope.row.pusherLevel | getDistributRoleName }})</p>
					<p style="margin: 0;">{{ scope.row.phoneNumber }}</p>
				</template>
			</el-table-column>
			<el-table-column prop="complaintContent" label="申诉内容"></el-table-column>
			<el-table-column prop="complaintPhone" label="预留电话"></el-table-column>
			<el-table-column label="申诉状态">
                <template slot-scope="scope">
					<p>{{ scope.row.complaintStatus == 1 ? '待处理' : scope.row.complaintStatus == 2 ? '撤销申诉' : scope.row.complaintStatus == 3 ? '申诉失败' : '申诉成功' }}</p>
				</template>
            </el-table-column>
			<el-table-column prop="createTime" label="申诉时间"></el-table-column>
			<el-table-column prop="adminName" label="处理人"></el-table-column>
			<el-table-column prop="handlerTime" label="处理时间"></el-table-column>
			<el-table-column label="操作" width="160">
				<template slot-scope="scope">
					<el-button type="text" class="pointers" @click="editOrder(scope)" v-if="scope.row.complaintStatus == 1">申诉处理</el-button>
					<el-button type="text" class="pointers" @click="showDetails(scope)">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
            :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange"
            @current-change="currentChange" background layout="total, sizes, prev, pager, next, jumper"></el-pagination>


		<!-- 申诉详情 -->
		<el-dialog title="查看详情" :visible.sync="showDetailFlag" width="800px" @closed="clearDataFunc">
			<EditAppeal v-if="showDetailData" :formData="showDetailData" @closeLayer="closedLayer"></EditAppeal>
		</el-dialog>

		<!-- 处理申诉 -->
		<el-dialog title="处理申诉" :visible.sync="editOrderFlag" width="800px" @closed="clearDataFunc">
			<EditAppeal v-if="editOrderData" :formData="editOrderData" @closeLayer="closedLayer" :isEdit='true'></EditAppeal>
		</el-dialog>
	</div>
</template>

<script>
	import { findPageComplaint, findByComplaintId } from '@/api/goods';
	import EditAppeal from './editAppeal';
	export default {
		components: {
			EditAppeal,
		},
		data() {
			return {
				tableLoading: false,
				createTimes: '',
				filter: {
					userId: '',
					nickName: '',
					afterSaleCode: '',
					complaintStatus: '',
					pageNo: 1,
					pageSize: 10,
					total: 0,
					list: [],
				},
				showDetailFlag: false,
				showDetailData: null,
				editOrderFlag: false,
				editOrderData: null,
			}
		},
		mounted() {
			this.search();
		},
		methods: {
			search() {
				this.filter.pageNo = 1;
				this.getList();
			},
			pageSizeChange(size) {
				this.filter.pageSize = size;
				this.getList();
			},
			currentChange(page) {
				this.filter.pageNo = page;
				this.getList();
			},
			// 获取列表
			async getList() {
				this.tableLoading = true;
				let params = {
					userId: this.filter.userId,
					nickName: this.filter.nickName,
					afterSaleCode: this.filter.afterSaleCode,
					complaintStatus: this.filter.complaintStatus,
					pageNo: this.filter.pageNo,
					pageSize: this.filter.pageSize,
				};
				if(this.createTimes) {
					params.begTime = this.createTimes[0];
					params.endTime = this.createTimes[1];
				}
				let { code, data } = await findPageComplaint(params);
				if (code === 200) {
					this.filter.list = data.records || [];
					this.filter.total = data.total;
				}
				setTimeout(() => {
					this.tableLoading = false;
				}, 300);
			},
			//显示详情
			async showDetails(row) {
                this.showDetailData = await this.getComplaint(row.row);
                this.showDetailData.complaintImg = JSON.parse(this.showDetailData.complaintImg);
				this.showDetailFlag = true;
			},
			//处理申诉
			async editOrder(row) {
				this.editOrderData = await this.getComplaint(row.row);
                this.editOrderData.complaintImg = JSON.parse(this.editOrderData.complaintImg);
                this.editOrderFlag = true;
			},
            //获取详情
            async getComplaint(row){
                let { code, data } = await findByComplaintId({id:row.id});
				if (code === 200) {
					return data;
				}
            },
			//关闭弹出层
			closedLayer(flag) {
				this.showDetailFlag = false;
				this.editOrderFlag = false;
				if(flag) {
					this.getList();
				}
			},
			//清楚选择内容
			clearDataFunc() {
				this.showDetailData = null;
				this.editOrderData = null;
			},
		}
	}
</script>